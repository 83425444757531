import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DataTableComponent} from './components/data-table/data-table.component';
import {CrDashboardComponent} from './cr-dashboard/cr-dashboard.component';

const routes: Routes = [
  {
    path: 'grant-detail',
    component: DataTableComponent,
    data: {detailType: 'grant'}
  }, {
    path: 'chart-detail',
    component: DataTableComponent,
    data: {detailType: 'chart'}
  }, {
    path: '',
    component: CrDashboardComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
