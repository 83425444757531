
<div class="header">
  <section></section>
  <section>
    <h3>
      {{title}}
      <ng-container *ngIf="!!chartTitle">
        - {{chartTitle}} <ng-container *ngIf="!!subCat">- {{subCat}}</ng-container>
      </ng-container>
    </h3>
  </section>
  <section>
    <button (click)="exportData()">Export to CSV</button>
  </section>
</div>
<div class="filters">
  <div>
    <label>Data Type:</label><div>{{selFilters['data_type'] === 'final' ? 'Final Only' : 'All'}}</div>
  </div>
  <ng-container *ngFor="let id of filterIds">
    <div *ngIf="!!selFilters[id]">
      <label>{{filterMap[id]}}:</label><div>{{selFilters[id]}}</div>
    </div>
  </ng-container>
</div>
<table>
  <tr>
    <th *ngFor="let label of colLabels">{{label}}</th>
  </tr>
  <tr *ngFor="let row of gridData">
    <td *ngFor="let col of cols; let i = index;">
      <ng-container *ngIf="col.id === 'ez_grant_id'">
        <a href="https://www.nfwf.org/grants/grants-library/profile?egid={{row[i]}}" target="_blank">{{row[i]}}</a>
      </ng-container>
      <ng-container *ngIf="col.id !== 'ez_grant_id'">
        {{row[i]}}
      </ng-container>
    </td>
  </tr>
</table>
