<div class="footer">
  <div class="text-block">
    <img src="/assets/NFWF-logo-white.svg">
    <p>
      1625 Eye Street NW, Suite 300
      <br />
      Washington, DC 20006
      <br />
      T 202-857-0166 | F 202-857-0162
    </p>
  </div>
</div>
