export interface MetricSummary {
  grants_benefitting_underserved: UnderservedData;
  grants_by_grant_type: GrantTypeData[];
  grants_by_grantee_type: GranteeTypeData[];
  grants_by_program: ProgramData[];
  measure_avgs: MeasureAvgData[];
  measure_sums: MeasureSumData[];
  sub_category_avgs: SubCategoryAvgData[];
  sub_category_sums: SubCategorySumData[];
}

export interface GrantInfoData {
  engaging_underserved: number;
  ez_grant_id: number;
  funds_awarded: string;
  grant_title: string;
  grant_type: string;
  grant_year: number;
  grantee: string;
  lat: string;
  lon: string;
  matching_funds: string;
  leverage_funds: string;
  program: string;
  region: string;
  resilience_activity: string;
  state: string;
  // Can store multiple arrays, each contains a long and lat
  points: [number, number][]
}

export interface GrantTypeData {
  funds_awarded: string;
  grant_count: number;
  grant_type: string;
  matching_funds: string;
  leverage_funds: string;
}

export interface GranteeTypeData {
  funds_awarded: string;
  grant_count: number;
  grantee_type: string;
  matching_funds: string;
  leverage_funds: string;
}

export interface UnderservedData {
  // Dollar amount as a string
  funds_awarded_benefitting_underserved: string;
  // Dollar amount as a string
  funds_awarded_total: string;
  grant_count_benefitting_underserved: number;
  grant_count_total: number;
}

export interface ProgramData {
  // Dollar amount as a string
  funds_awarded: string;
  // Number of grants
  grant_count: number;
  // Dollar amount as a string
  matching_funds: string;
  // Dollar amount as a string
  leverage_funds: string;
  // Name of the program
  program: string;
}

export interface MeasureSumData {
  // Which panel this data belongs to
  class: 'Ecological' | 'Socioeconomic';
  // Number of grants
  grant_count: number;
  // Which chart/diagram this measure ties to
  measure: string;
  // Decimal value that's stored as a string
  value: string;
}

export interface SubCategorySumData extends MeasureSumData {
  // Legend item
  sub_category: string;
}

export interface MeasureAvgData {
  // Which panel this data belongs to
  class: 'Ecological' | 'Socioeconomic';
  // Number of grants
  grant_count: number;
  // Which chart/diagram this measure ties to
  measure: string;
  // Decimal value that's stored as a string
  first_value: string;
  // Decimal value that's stored as a string
  last_value: string;
}

export interface SubCategoryAvgData extends MeasureAvgData {
  // Legend item
  sub_category: string;
}

// count = number of grants, defId = id of the definition in CHART_PROPERTIES or STAT_CARD properties
export interface GrantsObj {
  count: number;
  defId: string;
}

export const MARKER_LINE_PROPS = {
  color: '#CCCCCC',
  width: 1
};

export const BOTTOM_LEGEND_LAYOUT = {
  orientation: 'h',
  font: {
    size: 9
  },
  itemclick: false,
  itemdoubleclick: false,
  yanchor: 'top',
  xanchor: 'center',
  traceorder:  'normal',
  y: -.2,
  x: .5
}

export const GLOBAL_CHART_CONFIG = {
  displayModeBar: false,
  // modeBarButtons: [['toImage']],
  displaylogo: false,
  // staticPlot: true,
  scrollZoom: false,
  showAxisDragHandles: false
}

export const COLOR_RAMPS = {
  programSummary: [
    '#80c7da',
    '#008fba',
    '#00566c',
    '#e6f4f8',
    '#66bcd2',
    '#00485a',
    '#cce9f0',
    '#fcfbfd'
  ],
  ecological: [
    '#539536',
    '#a9ca9b',
    '#2a4b1b',
    '#84c394',
    '#35814a',
    '#d0ecc8',
  ],
  socioeconomic: [
    '#003258',
    '#005493',
    '#4d87b3',
    '#99bbd4',
    '#e6eef4'
  ]
}

export const FILTER_TYPES: any = {
  'Programs': 'programs',
  'Grant Types': 'grant-types',
  'Regions': 'regions',
  'States': 'states',
  'Congressional Districts': 'congressional-districts',
  'Resilience Activity': 'resilience-activities',
  'Award Year': 'award-years'
}

export const CHART_PROPERTIES: any = {
  grants: {
    title: 'Grants',
    msr: 'programs',
    panelClass:  '',
    grantDetailOverrides: {
      funds_awarded: {
        hide: false
      },
      matching_funds: {
        hide: false
      },
      leverage_funds: {
        hide: false
      }
    },
    chartDetailOverrides: null
  },
  granttypes: {
    title: 'Grant Types',
    msr: 'grant-types',
    panelClass:  '',
    grantDetailOverrides: null,
    chartDetailOverrides: null
  },
  granteetypes: {
    title: 'Grantee Types',
    msr: 'grantee-types',
    panelClass:  '',
    grantDetailOverrides: null,
    chartDetailOverrides: null
  },
  acresrestored: {
    title: 'Acres Restored',
    msr: 'Acres Restored',
    panelClass:  'Ecological',
    grantDetailOverrides: null,
    chartDetailOverrides: null
  },
  milesrestored: {
    title: 'Miles Restored & Created',
    msr: 'Miles Restored & Created',
    panelClass:  'Ecological',
    grantDetailOverrides: null,
    chartDetailOverrides: null
  },
  biomasschange: {
    title: 'Average Percent Change in Biomass',
    msr: 'Average % change in biomass',
    panelClass:  'Ecological',
    grantDetailOverrides: null,
    chartDetailOverrides: {
      final_value: {
        format: '1dp'
      },
      projected_value: {
        format: '1dp'
      }
    }
  },
  jobs: {
    title: 'Jobs Created',
    msr: 'Jobs Created',
    panelClass:  'Socioeconomic',
    grantDetailOverrides: null,
    chartDetailOverrides: {
      final_value: {
        label: 'Modeled',
        format: 'round'
      },
      projected_value: {
        hide: true
      }
    }
  },
  properties: {
    title: 'Properties Benefitting from Enhanced Protection',
    msr: 'Total # of Properties Benefitted from Enhanced Protection',
    panelClass:  'Socioeconomic',
    grantDetailOverrides: null,
    chartDetailOverrides: {
      final_value: {
        label: 'Modeled',
        format: 'round'
      },
      projected_value: {
        hide: true
      }
    }
  },
  reducedrepairs: {
    title: 'Avoided Property Damage & Reduced Repairs ($Millions)',
    msr: 'Avoided Property Damage & Reduced Repairs (Millions $)',
    panelClass:  'Socioeconomic',
    grantDetailOverrides: null,
    chartDetailOverrides: {
      final_value: {
        label: 'Modeled',
        format: 'mil'
      },
      projected_value: {
        hide: true
      }
    }
  },
  traveltime: {
    title: 'Avoided Travel Time Losses ($Thousands)',
    msr: 'Avoided Travel Time Losses (Thousands $)',
    panelClass:  'Socioeconomic',
    grantDetailOverrides: null,
    chartDetailOverrides: {
      final_value: {
        label: 'Modeled',
        format: 'k'
      },
      projected_value: {
        hide: true
      }
    }
  }
}

export const STAT_CARD_PROPERTIES: any = {
  species: {
    title: 'Species Benefitting from Restoration',
    msr: 'Species Benefitting from Restoration',
    panelClass:  'Ecological',
    grantDetailOverrides: null,
    chartDetailOverrides: null
  },
  fishpassage: {
    title: 'Barriers to Fish Passage Removed',
    msr: 'Barriers Removed',
    panelClass:  'Ecological',
    grantDetailOverrides: null,
    chartDetailOverrides: {
      final_value: {
        format: 'round'
      },
      projected_value: {
        format: 'round'
      }
    }
  },
  streammiles: {
    title: 'Stream Miles Opened',
    msr: 'Stream Miles Opened',
    panelClass:  'Ecological',
    grantDetailOverrides: null,
    chartDetailOverrides: null
  },
  abundancechange: {
    title: 'Average Percent Change in Abundance Oyster / Coral',
    msr: 'Average % change in species abundance',
    panelClass:  'Ecological',
    grantDetailOverrides: null,
    chartDetailOverrides: null
  },
  peoplebenefit: {
    title: 'People Benefitting from Restoration',
    msr: 'Total # of People Benefitting from Restoration',
    panelClass:  'Socioeconomic',
    grantDetailOverrides: null,
    chartDetailOverrides: {
      final_value: {
        format: 'round'
      },
      projected_value: {
        format: 'round'
      }
    }
  },
  underserved: {
    title: 'Grants Engaging Underserved Communities',
    msr: 'Grants Engaging Underserved Communities',
    panelClass:  'Socioeconomic',
    grantDetailOverrides: {
      engaging_underserved: {
        hide: false
      }
    },
    chartDetailOverrides: null
  },
}

/**
 * id: Name of the field returned by the api
 * label: What the column header should say in the table and export
 * format: The format we will send to the formatter function (default is null for no formatter)
 * hide: boolean, whether we should hide the column from the user (default is false)
 */
export const GRANT_DETAIL_COLS = [
  {
    id: 'grantee',
    label: 'Grantee',
    format: null,
    hide: false
  },
  {
    id: 'ez_grant_id',
    label: 'Grant #',
    format: null,
    hide: false
  },
  {
    id: 'grant_title',
    label: 'Grant Title',
    format: null,
    hide: false
  },
  {
    id: 'grant_type',
    label: 'Grant Type',
    format: null,
    hide: false
  },
  {
    id: 'region',
    label: 'Region',
    format: null,
    hide: false
  },
  {
    id: 'state',
    label: 'State',
    format: null,
    hide: false
  },
  {
    id: 'resilience_activity',
    label: 'Resilience Activity',
    format: null,
    hide: false
  },
  {
    id: 'grant_year',
    label: 'Award Year',
    format: null,
    hide: false
  },
  {
    id: 'program',
    label: 'Program',
    format: null,
    hide: false
  },
  {
    id: 'engaging_underserved',
    label: 'Engaging Underserved Communities',
    format: null,
    hide: true
  },
  {
    id: 'funds_awarded',
    label: 'Funds Awarded',
    format: 'currency',
    hide: true
  },
  {
    id: 'matching_funds',
    label: 'Matching Funds',
    format: 'currency',
    hide: true
  },
  {
    id: 'leverage_funds',
    label: 'Leveraged Funds',
    format: 'currency',
    hide: true
  },
];

export const CHART_DETAIL_COLS = [
  {
    id: 'grantee',
    label: 'Grantee',
    format: null,
    hide: false
  },
  {
    id: 'ez_grant_id',
    label: 'Grant #',
    format: null,
    hide: false
  },
  {
    id: 'grant_title',
    label: 'Grant Title',
    format: null,
    hide: false
  },
  {
    id: 'state',
    label: 'State',
    format: null,
    hide: false
  },
  {
    id: 'site',
    label: 'Site',
    format: null,
    hide: false
  },
  {
    id: 'sub_category',
    label: 'Subcategory',
    format: null,
    hide: false
  },
  {
    id: 'final_value',
    label: 'Final',
    format: '2dp',
    hide: false
  },
  {
    id: 'projected_value',
    label: 'Projected',
    format: '2dp',
    hide: false
  },
];

export function formatter(val: any, format: 'currency' | '2dp' | '1dp' | 'round' | 'mil' | 'k'): string {
  val = val === null ? '' : parseFloat(val.toString());
  if (isNaN(val)) {
    return '';
  }
  let us1 = new Intl.NumberFormat('en-US', {maximumFractionDigits: 1, minimumFractionDigits: 1});

  if (format === 'currency') {
    let usd = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return usd.format(val);
  } else if (format === '2dp') {
    let us2 = new Intl.NumberFormat('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2});
    return us2.format(val);
  } else if (format === '1dp') {
    return us1.format(val);
  } else if (format === 'round') {
    let us = new Intl.NumberFormat('en-US');
    return us.format(Math.round(val));
  } else if (format === 'mil') {
    // So for this one, Jim is returning the full dollar amount so we need to convert it to millions
    return '$' +  us1.format(val / 1000000) + 'M';
  } else {
    // For this one, Jim is returning the full dollar amount so we need to convert it to thousands
    return '$' +  us1.format(val / 1000) + 'k';
  }
}
