import {Component, Input, OnInit} from '@angular/core';
import {CHART_PROPERTIES, GLOBAL_CHART_CONFIG, STAT_CARD_PROPERTIES} from '../../nfwf-interface';
import {ApiCallsService} from '../../api-calls.service';

@Component({
  selector: 'app-chart-wrapper',
  templateUrl: './chart-wrapper.component.html',
  styleUrls: ['./chart-wrapper.component.scss']
})
export class ChartWrapperComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() layout: any = {};
  @Input() defId: string = '';
  // A class we will use to add override styles to some of the charts
  @Input() overrideClass: string = '';

  public GLOBAL_CHART_CONFIG = GLOBAL_CHART_CONFIG;

  // Every chart or stat card has a definition that identifies its:
  // title - The title that should be displayed in the chart details window or on the chart itself
  // msr - The msr value that is sent to the api to pull data
  // panelClass - The class value that is sent to the api when pulling data (charts on the program panel have no class)
  private _def!: {title: string, msr: string, panelClass: string};

  constructor(private _apiSvc: ApiCallsService) { }

  ngOnInit(): void {
    // Get the definition for the chart or stat card that we want to pull data for
    this._def = CHART_PROPERTIES[this.defId] || STAT_CARD_PROPERTIES[this.defId];
  }

  /**
   * Event triggerred when the user clicks on a portion of a chart
   * @param evt
   */
  public clickChart(evt: any): void {
    if (!this._def) {
      return;
    }
    // Some charts don't have a legend, so we can't use the name property since it may not be there
    // So we use the label, but if the label isn't something we want (it could be a numerical value)
    // we can instead add a meta object with the label we want (usually in stacked charts)
    const subCat = evt.points[0].meta|| evt.points[0].data.meta || evt.points[0].label;
    this._openDataTable(subCat);
  }

  /**
   * Event triggerred when the user clicks on a legend item
   * @param evt
   */
  public clickLegend(evt: any): void {
    if (!this._def) {
      return;
    }
    const index = evt.curveNumber;
    // The value for the legend is always the name property of the point
    // The ONE case where it's the label is the 3 pie charts for program summary
    // Since their data is divided into 3 different domains, the legend value is a label on
    // the main evt object
    const subCat = evt.data[index].name || evt.label;
    this._openDataTable(subCat);
  }

  private _openDataTable(subCat: string): void {
    // See if this is a program summary chart since they need to be treated differently
    // Program Summary charts don't have a panelClass so that's how we identify them
    const isProgSummary = !this._def.panelClass;
    // If the subCat = Total then there really is no subCat
    subCat = subCat.toUpperCase() === 'TOTAL' ? '' : subCat;
    let params = Object.assign({}, this._apiSvc.metricsFilters.value);
    // Program summary charts should open a grant detail table
    let api = isProgSummary ? 'grant-detail' : 'chart-detail';
    // Now, if this is a program summary then the msr is actually a metrics filter....make sure to add or replace it
    // If not a program summary chart then we need to add a class and measure param to the filters
    if (isProgSummary) {
      params[this._def.msr] = subCat;
    } else {
      Object.assign(params, {sub_category: subCat});
    }
    // Now pass the definition id
    Object.assign(params, {defId: this.defId});
    window.open('./' + api + '#' + encodeURIComponent(JSON.stringify(params)), '_blank', 'popup,width=1200,height=400');
  }

}
