<div class="d-flex w-100 main">
  <div class="db-filters">
    <app-filters-panel></app-filters-panel>
  </div>
  <div class="d-flex flex-col grow-1 db-content">
    <div>
      <div class="dashboard-label">Coastal Resilience Dashboard</div>
    </div>
    <div>
      <app-program-summary-panel></app-program-summary-panel>
    </div>
    <div>
      <app-ecological-benefits-panel></app-ecological-benefits-panel>
    </div>
    <div>
      <app-socioeconomic-panel></app-socioeconomic-panel>
    </div>
  </div>
</div>
<app-footer></app-footer>
