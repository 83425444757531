<app-dashboard-panel-template [label]="label" [includeDisclaimer]="includeDisclaimer" [aboutDataText]="panelClass">
  <div class="panel-row d-flex" *ngIf="!!restorationCard || !!jobsChartInfo">
    <div class="grow-1 d-flex align-items-center flex-center" *ngIf="!!restorationCard">
      <app-stat-card [title]="restorationCard.title" [image]="restorationCard.image" [stats]="restorationCard.stats"
                     [grants]="restorationCard.grants"></app-stat-card>
    </div>
    <div class="vertical-rule" *ngIf="!!restorationCard && !!jobsChartInfo"></div>
    <div class="w-60" *ngIf="!!jobsChartInfo">
      <ng-container *ngTemplateOutlet="chart; context:{chartObj: jobsChartInfo}"></ng-container>
    </div>
  </div>

  <div class="panel-row" *ngIf="!!communitiesCard">
    <app-stat-card [title]="communitiesCard.title" [image]="communitiesCard.image" [stats]="communitiesCard.stats"
                   [grants]="communitiesCard.grants" [footnotes]="communitiesCard.footnotes" [tooltipText]="communitiesCard.tooltip"
    ></app-stat-card>
  </div>

  <div class="panel-row d-flex" *ngIf="!!propsChartInfo || !!repairsChartInfo">
    <div [ngClass]="{'shared': !!repairsChartInfo, 'solo': !repairsChartInfo}" *ngIf="!!propsChartInfo">
      <ng-container *ngTemplateOutlet="propertiesGrid; context:{chartObj: propsChartInfo}"></ng-container>
    </div>
    <div *ngIf="!!repairsChartInfo">
      <ng-container *ngTemplateOutlet="chart; context:{chartObj: repairsChartInfo}"></ng-container>
    </div>
  </div>

  <div *ngIf="!!travelChartInfo" class="panel-row">
    <ng-container *ngTemplateOutlet="chart; context:{chartObj: travelChartInfo}"></ng-container>
  </div>
</app-dashboard-panel-template>


<ng-template #chart let-chartObj="chartObj">
  <div>
    <div class="stat-title">{{chartObj.title}}<app-info-button [tooltipText]="chartObj.tooltip"></app-info-button></div>
    <app-grants-link [grants]="chartObj.grants"></app-grants-link>
    <div *ngIf="!!chartObj.subtitle" class="stat-subtitle">{{chartObj.subtitle}}</div>
    <app-chart-wrapper [data]="chartObj.traceData" [layout]="chartObj.layout" [class]="panelClass"
                       [defId]="chartObj.defId" [overrideClass]="chartObj.overrideClass"></app-chart-wrapper>
  </div>
</ng-template>

<ng-template #propertiesGrid let-chartObj="chartObj">
  <div class="property-grid">
    <div class="stat-title">{{chartObj.title}}<app-info-button [tooltipText]="chartObj.tooltip"></app-info-button></div>
    <app-grants-link [grants]="chartObj.grants"></app-grants-link>
    <div *ngIf="!!chartObj.subtitle" class="stat-subtitle">{{chartObj.subtitle}}</div>
    <div class="table">
      <div *ngFor="let val of chartObj.traceData.vals; let i = index;" class="row" (click)="openDataTableForProperties(chartObj.traceData.labels[i])">
        <div class="icon">
          <img src="{{chartObj.traceData.images[i]}}">
        </div>
        <div class="label">
          {{chartObj.traceData.labels[i]}}
        </div>
        <div class="stat">
          {{val}}
        </div>
      </div>
    </div>
  </div>
</ng-template>
