<app-dashboard-panel-template [label]="label" [includeDisclaimer]="includeDisclaimer" [aboutDataText]="'Program'">
  <div class="panel-row">
    <app-overview-map></app-overview-map>
  </div>

  <div class="panel-row relative">
    <app-info-button [tooltipText]="leveragedFundsInfoText" class="app-info-custom"></app-info-button>
    <app-chart-wrapper [data]="tripleChartData" [layout]="tripleChartLayout" [defId]="'grants'" overrideClass="pieChartsOverride"></app-chart-wrapper>
  </div>
  <div class="panel-row" *ngIf="!!grantChartInfo">
    <ng-container *ngTemplateOutlet="stackedChart; context:{chartObj: grantChartInfo}"></ng-container>
  </div>
  <div class="panel-row" *ngIf="!!granteeChartInfo">
    <ng-container *ngTemplateOutlet="stackedChart; context:{chartObj: granteeChartInfo}"></ng-container>
  </div>
</app-dashboard-panel-template>

<ng-template #stackedChart let-chartObj="chartObj">
  <div>
    <div class="stat-title">{{chartObj.title}}</div>
    <app-chart-wrapper [data]="chartObj.traceData" [layout]="chartObj.layout" [defId]="chartObj.defId"></app-chart-wrapper>
  </div>
</ng-template>
