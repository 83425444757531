import {Component, Input, OnInit} from '@angular/core';
import {GrantsObj} from '../../nfwf-interface';
import {ApiCallsService} from '../../api-calls.service';

@Component({
  selector: 'app-stat-card',
  templateUrl: './stat-card.component.html',
  styleUrls: ['./stat-card.component.scss']
})

// TODO: Should stat cards show a 0 value or just be removed if 0?

export class StatCardComponent implements OnInit {
  @Input() title!: string;
  @Input() grants?: GrantsObj;
  @Input() image!: string;
  @Input() stats!: string[];
  @Input() footnotes?: string[];
  @Input() subtitle?: string;
  @Input() tooltipText?: string;
  // Pass a definition id if you want to the chart detail window to be active
  @Input() defId?: string;

  get isDouble(): boolean {
    return this.stats.length > 1;
  }

  get isClickable(): boolean {
    return !!this.defId;
  }

  constructor(private _apiSvc: ApiCallsService) { }

  ngOnInit(): void {
  }

  public openDataTable(): void {
    if (this.isClickable) {
      let params = Object.assign({}, this._apiSvc.metricsFilters.value);
      // Now pass the definition id
      Object.assign(params, {defId: this.defId});
      window.open('./chart-detail#' + encodeURIComponent(JSON.stringify(params)), '_blank', 'popup,width=1200,height=400');
    }
  }

}
