import {Component, OnInit} from '@angular/core';
import {
  GRANT_DETAIL_COLS,
  CHART_DETAIL_COLS,
  FILTER_TYPES, CHART_PROPERTIES, STAT_CARD_PROPERTIES
} from '../../nfwf-interface';
import {ApiCallsService} from '../../api-calls.service';
import {ActivatedRoute} from '@angular/router';
import {saveAs} from 'file-saver';
import {formatter} from '../../nfwf-interface';


@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})

export class DataTableComponent implements OnInit {
  public gridData: any[] = [];
  public measure: string = '';
  public subCat: string = '';
  public cols: {id: string, label: string, format: any, hide: boolean}[] = [];
  public colLabels: string[] = [];
  public detailType: 'grant' | 'chart' = 'grant';
  public eusOptions: string[] = ['N/A', 'Yes'];
  public filterMap: any = {};
  public selFilters: any = {};
  public filterIds: string[] = Object.values(FILTER_TYPES);
  public chartTitle: string = '';

  // Every chart or stat card has a definition that identifies its:
  // title - The title that should be displayed in the chart details window or on the chart itself
  // msr - The msr value that is sent to the api to pull data
  // panelClass - The class value that is sent to the api when pulling data (charts on the program panel have no class)
  private _def!: {title: string, msr: string, panelClass: string, grantDetailOverrides: any, chartDetailOverrides: any};

  public get title(): string {
    return this.detailType === 'grant' ? 'Grant Details' : 'Chart Details';
  }

  constructor(private _apiSvc: ApiCallsService, private _aRoute: ActivatedRoute) {
    // Need to create a reverse map of the filter ids and names
    Object.keys(FILTER_TYPES).forEach((key: string) => this.filterMap[FILTER_TYPES[key]] = key);
    // Ok, we need to add the 'grantee-types' filter to our map and array
    // This is NOT a filter that the user can add
    // It's only available by clicking on the Grantee Types bar chart on the Program Summary panel
    this.filterMap['grantee-types'] = 'Grantee Types';
    this.filterIds.push('grantee-types');
  }

  ngOnInit(): void {
    this.detailType = this._aRoute.snapshot.data['detailType'];
    const frag = this._aRoute.snapshot.fragment;
    if (!!frag) {
      const filters = JSON.parse(frag);
      // Get the definition for the chart or stat card that we want to pull data for
      this._def = CHART_PROPERTIES[filters.defId] || STAT_CARD_PROPERTIES[filters.defId];
      // Now use the definition to set our measure, class and title (instead of passing these values in the url)
      this._setPropertiesFromDef(filters);
      const apiObs = this.detailType === 'grant' ? this._apiSvc.getGrantInfo(filters) : this._apiSvc.getMetricsInfo(filters);
      // Now figure out which columns we need to use for our table
      this._identifyColumns();
      // Only exists for chart detail tables
      this.subCat = filters.sub_category;
      // Set the selected filters object so we can display the filters in the UI
      this.selFilters = filters;
      // Now trigger the observable and pull the data for the grid
      apiObs.subscribe((data) => {
        // Loop through each row of data and format any of the data that needs it and remove any that shouldn't be displayed
        const tmpData: any[] = [];
        data.forEach((row: any) => {
          const tmpRow: any[] = [];
          this.cols.forEach((col: any) => {
            const val = row.hasOwnProperty(col.id) ? row[col.id] : '';
            // If this column has a formatter assigned to it then use that first
            // If the column is engaging_underserved then we need to convert it to a Yes or N/A value
            tmpRow.push(!!col.format ? formatter(val, col.format) : (col.id === 'engaging_underserved' ? this.eusOptions[val]: val));
          });
          tmpData.push(tmpRow);
        });
        this.gridData = tmpData;
      });
    }
  }

  private _setPropertiesFromDef(filters: any): void {
    const panelClass = this._def.panelClass;
    this.measure = this._def.msr;
    this.chartTitle = this._def.title;
    // We need to add the panelClass (if there is a value) to the filters for the api call
    if (!!panelClass) {
      Object.assign(filters, {class: panelClass});
    }
    // We need to add the measure to the filters for the api call
    Object.assign(filters, {measure: this.measure});
  }

  private _identifyColumns(): void {
    const tmpCols = this.detailType === 'grant' ? GRANT_DETAIL_COLS.slice() : CHART_DETAIL_COLS.slice();
    // Now update the columns with any possible overrides
    const overrides = this.detailType === 'grant' ? this._def.grantDetailOverrides : this._def.chartDetailOverrides;
    // If we do have overrides then we want to go through each one and assign them to the tmpCols
    if (!!overrides) {
      tmpCols.map((col: any) => {
        return !!overrides[col.id] ? Object.assign(col, overrides[col.id]) : col;
      });
    };
    this.cols = tmpCols.filter((o: any) => o.hide === false);
    this.colLabels = this.cols.map(o => o.label);
  }

  public exportData(): void {
    const filename = this.title + (!!this.chartTitle ? '-' + this.chartTitle : '') + (!!this.subCat ? '-' + this.subCat : '') + '.csv';
    // Loop through the data and convert it to a csv file
    const fileData = this.gridData.map((row: any) => '"' + Object.values(row).join('","') + '"');
    // Now push the headers to the first row of the data
    fileData.unshift(this.colLabels.join(','));
    // Now export the file
    const blob = new Blob([fileData.join('\n')], {type: 'text/plain;charset=utf-8'});
    saveAs(blob, filename);
  }

}
