<div class="panel-main">
  <div class="panel-info-section">
    <div class="panel-label">{{label}}</div>
    <div class="disclaimer" *ngIf="includeDisclaimer">
      <br>
      (# Grants) refers to the number of grants contributing to each metric.
      <br><br>
      Click to see details of contributing grants.
    </div>
    <div class="about-data">
      <div class="about-data-hdr">About the {{aboutDataText !== 'Program' ? aboutDataText + ' ' : ''}}Data</div>
      <div>
        <ng-container *ngIf="aboutDataText === 'Program'">
          This dashboard shows grantee-reported data, including projected values for projects in progress and actual values for completed projects. Data from active projects are subject to change and may not reflect the current status of restoration activities. The map will display multiple points for an individual grant if project sites are more than 20 miles apart. <br><br>Data accurate as of {{asOfDate | date:'shortDate'}}
        </ng-container>
        <ng-container *ngIf="aboutDataText === 'Ecological'">
          Data on species benefitting come from NFWF’s <a href="https://www.nfwf.org/programs/national-coastal-resilience-fund/regional-coastal-resilience-assessments" target="_blank">Regional Coastal Resilience Assessments</a> based upon an evaluation of habitats that could support species of conservation concern. Data on percent change in biomass and abundance come from monitoring data uploaded to NFWF’s <a href="https://resiliencedata.nfwf.org/" target="_blank">Coastal Resilience Open Data Platform</a>.
        </ng-container>
        <ng-container *ngIf="aboutDataText === 'Socioeconomic'">
          This data is developed from a contractor-led assessment of the socioeconomic benefits on a subset of NFWF-funded coastal resilience projects and NFWF’s jobs calculator.
        </ng-container>
      </div>
    </div>
  </div>
  <div class="panel-chart-section">
    <!-- Should take any content that was passed by the parent -->
    <ng-content></ng-content>
  </div>
</div>

