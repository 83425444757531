import { Component, OnInit } from '@angular/core';
import {
  COLOR_RAMPS,
  MARKER_LINE_PROPS,
  STAT_CARD_PROPERTIES,
  CHART_PROPERTIES,
  MetricSummary,
  MeasureSumData,
  UnderservedData, GrantsObj, SubCategorySumData
} from "../../nfwf-interface";
import {ApiCallsService} from '../../api-calls.service';
import {tap} from 'rxjs';

@Component({
  selector: 'app-socioeconomic-panel',
  templateUrl: './socioeconomic-panel.component.html',
  styleUrls: ['./socioeconomic-panel.component.scss']
})

export class SocioeconomicPanelComponent implements OnInit {
  public panelClass = 'Socioeconomic';
  public label = 'Socioeconomic Highlights';
  public includeDisclaimer = true;
  private _measureSums: MeasureSumData[] = [];
  private _subCategorySums: SubCategorySumData[] = [];

  public communitiesCard: any = null;
  public restorationCard: any = null;

  public jobsChartInfo!: { traceData: any[], layout: any, grants: GrantsObj, title: string, defId: string, tooltip?: string};
  public propsChartInfo!: { traceData: any[], layout: any, grants: GrantsObj, title: string, subtitle: string, defId: string, tooltip?: string};
  public travelChartInfo!: { traceData: any[], layout: any, grants: GrantsObj, title: string, subtitle: string, defId: string, tooltip?: string, overrideClass?: string} | null;
  public repairsChartInfo!: { traceData: any[], layout: any, grants: GrantsObj, title: string, defId: string, tooltip?: string, overrideClass?: string} | null;

  constructor(private _apiSvc: ApiCallsService) { }

  ngOnInit(): void {
    // Subscribe to the metrics data pull
    this._apiSvc.metricsDataObs$.pipe(
      tap((data: MetricSummary) => {
        this._subCategorySums = data.sub_category_sums;
        this._measureSums = data.measure_sums;
        this._buildTravelChart();
        this._buildRepairsChart();
        this.jobsChartInfo = this._buildVerticalBarChart('jobs');
        this.propsChartInfo = this._buildPropertiesChart();
        this.restorationCard = this._buildRestorationCard();
        this.communitiesCard = this._buildCommunitiesCard(data.grants_benefitting_underserved);
      })
    ).subscribe();
  }

  private _buildVerticalBarChart(chartType: 'jobs'): any {
    const labels: string[] = [];
    const vals: number[] = [];
    const colorramp = COLOR_RAMPS.socioeconomic;
    const msr = CHART_PROPERTIES[chartType].msr;
    const msrSum = this._measureSums.find((o: MeasureSumData) => o.class === this.panelClass && o.measure === msr);
    const chartTitle = CHART_PROPERTIES[chartType].title;
    const tooltipText = '';


    this._subCategorySums.sort((a, b) => Number(a.value) < Number(b.value) ? -1 : 1)
      .forEach((o: SubCategorySumData) => {
        if (o.class === this.panelClass && o.measure === msr) {
          labels.push(o.sub_category);
          vals.push(Math.round(Number(o.value)));
        }
      });

    // If we have more than one metric we need to add a Totals bar to the beginning of the chart
    if (labels.length > 1) {
      labels.unshift('Total');
      vals.unshift(Math.round(Number(msrSum?.value || 0)))
    }

    const data = [{
        x: labels,
        y: vals,
        type: 'bar',
        texttemplate: '<b>%{y:,}</b>',
        textposition: 'auto',
        textfont: {
          size: 11
        },
        hoverinfo: 'none',
        marker: {
          color: colorramp,
          line: MARKER_LINE_PROPS
        },
      }];

    if (labels.length > 0) {
      return  {
        traceData: data,
        layout: this._buildVertChartLayout(labels),
        title: chartTitle,
        grants: {count: msrSum?.grant_count || 0, defId: chartType},
        tooltip: tooltipText,
        defId: chartType
      }
    } else {
      return null;
    }
  }

  private _buildPropertiesChart(): any {
    const labels: string[] = [];
    const vals: string[] = [];
    const images: string[] = [];
    const colors: string[] = [];
    const chartType = 'properties';
    const colorramp = COLOR_RAMPS.socioeconomic;
    const msr = CHART_PROPERTIES[chartType].msr;
    const msrSum = this._measureSums.find((o: MeasureSumData) => o.class === this.panelClass && o.measure === msr);
    const chartTitle = CHART_PROPERTIES[chartType].title;
    const tooltipText = 'The number of properties by type that experience a reduced risk of storm-related damage because of the resilience project.';

    this._subCategorySums.sort((a, b) => Number(a.value) > Number(b.value) ? -1 : 1)
      .forEach((o: SubCategorySumData) => {
        if (o.class === this.panelClass && o.measure === msr) {
          labels.push(o.sub_category);
          vals.push(Math.round(Number(o.value)).toLocaleString());
          if (o.sub_category.toLowerCase() === 'public') {
            colors.push(colorramp[0]);
            images.push('./assets/PublicIcon.svg');
          } else if (o.sub_category.toLowerCase() === 'commercial') {
            colors.push(colorramp[3]);
            images.push('./assets/CommercialIcon.svg');
          } else if (o.sub_category.toLowerCase() === 'residential') {
            colors.push(colorramp[2]);
            images.push('./assets/ResidentialIcon.svg');
          } else {
            // Critical Infrastructure
            colors.push(colorramp[1]);
            images.push('./assets/InfrastructureIcon.svg');
          };
        }
      });

    const data = {
      vals: vals,
      labels: labels,
      colors: colors,
      images: images,
      meta: labels,
    };

    if (labels.length > 0) {
      return  {
        traceData: data,
        title: chartTitle,
        subtitle: (Number(msrSum?.value) || 0).toLocaleString(),
        grants: {count: msrSum?.grant_count || 0, defId: chartType},
        tooltip: tooltipText,
        defId: chartType
      }
    } else {
      return null;
    }
  }


  private _buildTravelChart(): void {
    // Define the seq that we want to see the data in
    const seq = ['Air', 'Rail', 'Auto'];
    const labels: string[] = [];
    const vals: number[] = [];
    const msr = CHART_PROPERTIES['traveltime'].msr;
    const msrSum = this._measureSums.find((o: MeasureSumData) => o.class === this.panelClass && o.measure === msr);
    const colorramp = COLOR_RAMPS.socioeconomic;
    const colors = [colorramp[1], colorramp[3], colorramp[0], colorramp[2], colorramp[4]];
    const chartTitle = CHART_PROPERTIES['traveltime'].title;
    this._subCategorySums.sort((a, b) => seq.indexOf(a.sub_category) > seq.indexOf(b.sub_category) ? -1 : 1)
      .forEach((o: SubCategorySumData) => {
      if (o.class === this.panelClass && o.measure === msr) {
        labels.push(o.sub_category);
        vals.push((Math.round(Number(o.value)/1000)));
      }
    });

    const data = [{
      x: vals,
      y: labels,
      orientation: 'h',
      type: 'bar',
      texttemplate: '<b>%{x:,} </b>',
      textposition: 'auto',
      textfont: {
        size: 11
      },
      hoverinfo: 'none',
      marker: {
        color: colors,
        line: MARKER_LINE_PROPS
      },
    }];

    const layout = {
      height: 200,
      autosize: true,
      margin: {
        b: 0,
        l: 0,
        r: 0,
        t: 0
      },
      images: this._buildImgLayout(labels, vals),
      xaxis: {
        visible: false,
        fixedrange: true,
        autorange: false,
        range: [0, (Math.max(...vals) * 1.2)]
      },
      yaxis: {
        fixedrange: true
      }
    };

    if (labels.length > 0) {
      this.travelChartInfo = {
        traceData: data,
        layout: layout,
        title: chartTitle,
        overrideClass: 'travelChartOverride',
        grants: {count: msrSum?.grant_count || 0, defId: 'traveltime'},
        subtitle: (Math.round(Number(msrSum?.value || 0)/1000)).toLocaleString(),
        defId: 'traveltime',
        tooltip: 'These benefits are the economic value of reduced flood-related travel delays. The present value benefits assume a 3% discount rate and are calculated over the expected design life of each resilience project. Expected design life varies depending on site conditions and project design.'
      }
    } else {
      this.travelChartInfo = null;
    }
  }

  private _buildImgLayout(labels: string[], vals: number[]): any[] {
    const imgs: any = {
      Auto: '/assets/noun-car-4612699-005493.png',
      Rail: '/assets/noun-train-12623-99BBD4.png',
      Air: '/assets/noun-airplane-1190645-003258.png'
    }

    return labels.map((label: string, idx: number) => ({
      x: vals[idx],
      y: idx,
      sizex: (Math.max(...vals) * .1),
      sizey: 1,
      source: imgs[label],
      xanchor: "left",
      xref: "x",
      yanchor: "middle",
      yref: "y",
      sizing: "contain"
    }));
  }

  private _buildVertChartLayout(labels: string[]): any {
    return {
      height: 200,
      autosize: true,
      font: {
        size: 14
      },
      margin: {
        b: 30,
        l: 0,
        r: 0,
        t: 0
      },
      xaxis: {
        tickmode: 'array',
        tickvals: labels.map((l, i) => {return i}),
        ticktext: labels.map(l => '<b>' + l.replace(' ', '<br>') + '</b>'),
        tickfont: {
          size: 9
        },
        fixedrange: true
      },
      yaxis: {
        visible: false,
        fixedrange: true
      }
    };
  }

  private _buildRepairsChart(): void {
    const vals: number[] = [];
    const labels: string[] = [];
    const msr = CHART_PROPERTIES['reducedrepairs'].msr;
    const msrSum = this._measureSums.find((o: MeasureSumData) => o.class === this.panelClass && o.measure === msr);
    const colorramp = COLOR_RAMPS.socioeconomic;
    const colors = [colorramp[1], colorramp[4], colorramp[0], colorramp[2], colorramp[3]];
    const chartTitle = CHART_PROPERTIES['reducedrepairs'].title;

    this._subCategorySums.sort((a, b) => Number(a.value) > Number(b.value) ? -1 : 1)
      .forEach((o: SubCategorySumData) => {
        if (o.class === this.panelClass && o.measure === msr) {
          labels.push(o.sub_category);
          vals.push((Math.round(Number(o.value)/100000)/10));
        }
      });

    const data = [{
      values: vals,
      labels: labels,
      type: 'pie',
      hole: .4,
      textposition: 'inside',
      textinfo: 'value',
      marker: {
        colors: colors,
        line: MARKER_LINE_PROPS
      },
      hoverinfo: 'none',
    }];

    const layout = {
      height: 240,
      autosize: true,
      margin: {
        l: 0,
        r: 0,
        b: 20,
        t: 10
      },
      legend: {
        orientation: 'h',
        font: {
          size: 9
        },
        itemclick: false,
        itemdoubleclick: false,
        yanchor: 'top',
        y: 0
      },
      annotations: [
        {
          font: {
            size: 20
          },
          showarrow: false,
          text: '<b>' + (Math.round(Number(msrSum?.value || 0)/100000)/10).toLocaleString() + '</b>',
          xanchor: 'center',
          x: .5,
          y: .5
        }
      ]
    };

    this.repairsChartInfo = vals.length === 0 ? null : {
      traceData: data,
      layout: layout,
      title: chartTitle,
      overrideClass: 'pieChartsOverride',
      grants: {count: msrSum?.grant_count || 0, defId: 'reducedrepairs'},
      defId: 'reducedrepairs',
      tooltip: 'These benefits are the economic value of reduced damages to properties (e.g., houses, buildings, roads, and other infrastructure) measured in terms of reduced replacement or maintenance costs. The present value benefits assume a 3% discount rate and are calculated over the expected design life of each resilience project. Expected design life varies depending on site conditions and project design.'
    }
  }

  private _buildRestorationCard(): any {
    const msr = STAT_CARD_PROPERTIES['peoplebenefit'].msr;
    const data = this._measureSums.find((o: MeasureSumData) => o.class === this.panelClass && o.measure === msr);
    const chartTitle = STAT_CARD_PROPERTIES['peoplebenefit'].title;
    return !data ? null : {
      title: chartTitle,
      image: 'RecreationSceneNewBlues-03.png',
      grants: {count: data.grant_count, defId: 'peoplebenefit'},
      stats: [Math.round(Number(data.value)).toLocaleString()]
    }
  }

  private _buildCommunitiesCard(data: UnderservedData): any {
    const msr = STAT_CARD_PROPERTIES['underserved'].msr;
    const title = STAT_CARD_PROPERTIES['underserved'].title;
    // Don't create the card if there is no data or the data is returned and the grant_count_benefitting_underserved = 0
    return !data || data.grant_count_benefitting_underserved === 0 ? null : {
      title: title,
      image: 'noun-family-New Blue-01.png',
      grants: {count: data.grant_count_benefitting_underserved, defId: 'underserved'},
      stats: [data.grant_count_benefitting_underserved, '$' + (Math.round(Number(data.funds_awarded_benefitting_underserved)/100000)/10).toLocaleString() + 'M'],
      footnotes: ['Grants Awarded', 'Funds Awarded'],
      tooltip: 'For grants starting in 2021, NFWF evaluated proposals for engagement of underserved communities based upon socioeconomic demographic information and applicant submissions related to community engagement activities.'
    }
  }

  public openDataTableForProperties(subCat: string): void {
    let params = Object.assign({}, this._apiSvc.metricsFilters.value);
    Object.assign(params, {sub_category: subCat, defId: 'properties'});
    window.open('./chart-detail#' + encodeURIComponent(JSON.stringify(params)), '_blank', 'popup,width=1200,height=400');
  }

}
