<!-- Header if this is a double stat card -->
<ng-container *ngTemplateOutlet="isDouble ? statTitle : null"></ng-container>
<!-- Grant link for double stat cards -->
<app-grants-link *ngIf="isDouble && !!grants" [grants]="grants"></app-grants-link>
<!-- Subtitle -->
<div *ngIf="!!subtitle" class="stat-subtitle">{{subtitle}}</div>
<!-- Main body of the stat card -->
<div class="d-flex" [ngClass]="{'top-margin': isDouble}">
  <!-- Left stat if this is a double stat card -->
  <ng-container *ngIf="isDouble">
    <ng-container *ngTemplateOutlet="statDisplay; context: {statIndex: 0}"></ng-container>
  </ng-container>
  <!-- Image container -->
  <div class="stat-img-wrapper" [ngClass]="{'clickable': isClickable}">
    <img src="/assets/{{image}}" (click)="openDataTable()">
  </div>
  <!-- Right stat -->
  <ng-container *ngTemplateOutlet="statDisplay; context: {statIndex: stats.length - 1}"></ng-container>
</div>

<ng-template #statDisplay let-statIndex="statIndex">
  <div class="d-flex flex-col flex-center">
    <!-- Stat card title (for single stat cards only) -->
    <ng-container *ngTemplateOutlet="!isDouble ? statTitle : null"></ng-container>
    <!-- Grant Link (for single stat cards only -->
    <app-grants-link *ngIf="!isDouble && !!grants" [grants]="grants"></app-grants-link>
    <!-- Stat -->
    <div class="stat-stat">
      {{stats[statIndex]}}
    </div>
    <!-- Stat footer (for double stat cards) -->
    <div *ngIf="isDouble && !!footnotes" class="stat-footnote">
      {{footnotes[statIndex]}}
    </div>
  </div>
</ng-template>


<ng-template #statTitle>
  <div class="stat-title">{{title}}<app-info-button [tooltipText]="tooltipText"></app-info-button></div>
</ng-template>
