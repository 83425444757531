import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject, tap} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../environments/environment';
import {MetricSummary} from './nfwf-interface';

@Injectable({
  providedIn: 'root'
})

export class ApiCallsService {
  private _metricsDataObs: Subject<MetricSummary> = new Subject();
  public metricsDataObs$: Observable<MetricSummary> = this._metricsDataObs.asObservable();
  public metricsFilters: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public asOfDate: string = '';

  constructor(private _http: HttpClient) { }

  /**
   * Internal call that returns an HTTP observable for a standard GET call
   * @param list: The id of the filter we want to get values for
   * @param filters: The values of earlier filters
   */
  public getListValues(list: string, filters?: any): Observable<any> {
    const url = environment.apiServer + 'filter-list';
    const params = this.buildParams(Object.assign({}, {list: list}, filters));
    return this._http.get(url, params);
  }

  public getMetrics(filters: any): Observable<MetricSummary> {
    const url = environment.apiServer + 'metrics-summary';
    const params = this.buildParams(filters);
    return this._http.get(url, params).pipe(
      tap((data: any) => {
        // Now update the metricsFilters for the grants links
        this.metricsFilters.next(filters);
        // And update the asOfDate
        this.asOfDate = data.as_of_date;
        // Trigger the metricsDataObs to update all the charts and stat cards
        this._metricsDataObs.next(data);
      })
    );
  }

  public exportData(filters: any): Observable<any> {
    const url = environment.apiServer + 'export-detail';
    const params = this.buildParams(filters);
    return this._http.get(url, params);
  }

  /**
   * Called when we want to pull info for the Grants Table
   * @param filters
   */
  public getGrantInfo(filters: any): Observable<any> {
    const url = environment.apiServer + 'grants-detail';
    const params = this.buildParams(filters);
    return this._http.get(url, params);
  }

  /**
   * Called when we want to pull info for the Charts table
   * @param filters
   */
  public getMetricsInfo(filters: any): Observable<any> {
    const url = environment.apiServer + 'metrics-detail';
    const params = this.buildParams(filters);
    return this._http.get(url, params);
  }

  public buildParams(params: any): any {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const HTTP_OPTIONS: any = {headers: headers};
    // Clean up the params so we don't include any params that don't have values
    const cleanParams: any = {};
    if (!!params) {
      Object.keys(params).forEach((key: string) => {
        if (!!params[key].length) {
          cleanParams[key] = params[key]
        }
      })
    }
    return Object.assign({}, HTTP_OPTIONS, !!params ? {params: params} : {});
  }
}
