import {Component, Input, OnInit} from '@angular/core';
import {ApiCallsService} from '../../api-calls.service';

@Component({
  selector: 'app-dashboard-panel-template',
  templateUrl: './dashboard-panel-template.component.html',
  styleUrls: ['./dashboard-panel-template.component.scss']
})
export class DashboardPanelTemplateComponent implements OnInit {
  @Input() label = '';
  @Input() includeDisclaimer = false;
  @Input() aboutDataText = '';
  public get asOfDate(): string{
    return this._apiSvc.asOfDate;
  };

  public showText: boolean = false;

  constructor(private _apiSvc: ApiCallsService) { }

  ngOnInit(): void {
  }

  public toggleText(): void {
    this.showText = !this.showText;
  }

}
