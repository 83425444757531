import {Component, Input, OnInit} from '@angular/core';
import {ApiCallsService} from '../../api-calls.service';
import {GrantsObj} from '../../nfwf-interface';

@Component({
  selector: 'app-grants-link',
  templateUrl: './grants-link.component.html',
  styleUrls: ['./grants-link.component.scss']
})

export class GrantsLinkComponent implements OnInit {
  // count = number of grants, defId = id of the definition in CHART_PROPERTIES or STAT_CARD properties
  @Input() grants: GrantsObj = {count: 0, defId: ''};

  constructor(private _apiSvc: ApiCallsService) { }

  ngOnInit(): void {

  }

  public viewGrantInfo(evt: any): void {
    let filters = Object.assign({}, this._apiSvc.metricsFilters.value, {defId: this.grants.defId});
    window.open('./grant-detail#' + encodeURIComponent(JSON.stringify(filters)), '_blank', 'popup,width=1200,height=400');
    evt.preventDefault();
  }

}
