<app-dashboard-panel-template *ngIf="displayPanel" [label]="label" [includeDisclaimer]="includeDisclaimer" [aboutDataText]="panelClass">
  <div class="panel-row" *ngIf="!!acresChartInfo">
    <ng-container *ngTemplateOutlet="stackedChart; context:{chartObj: acresChartInfo}"></ng-container>
  </div>
  <div class="panel-row" *ngIf="!!milesChartInfo">
    <ng-container *ngTemplateOutlet="stackedChart; context:{chartObj: milesChartInfo}"></ng-container>
  </div>

  <div class="panel-row d-flex" *ngIf="!!statCards.length">
    <app-stat-card *ngFor="let card of statCards"
      [title]="card.title" [image]="card.img" [stats]="[card.stats.toString(10)]"
       [grants]="card.grants" [defId]="card.defId"></app-stat-card>
  </div>

  <div class="panel-row d-flex" *ngIf="!!biomassChartInfo || !!oysterCard">
    <div *ngIf="!!biomassChartInfo" [ngClass]="{'shared': !!oysterCard, 'solo': !oysterCard}">
      <div>
        <div class="stat-title">{{biomassChartInfo.title}}</div>
        <app-grants-link [grants]="biomassChartInfo.grants"></app-grants-link>
        <div *ngIf="!!biomassChartInfo.subtitle" class="stat-subtitle">{{biomassChartInfo.subtitle}}</div>
        <app-chart-wrapper [data]="biomassChartInfo.traceData" [layout]="biomassChartInfo.layout" [class]="panelClass"
                           [defId]="biomassChartInfo.defId"></app-chart-wrapper>
      </div>
    </div>
    <div class="grow-1" *ngIf="!!oysterCard">
      <app-stat-card *ngIf="!!oysterCard" [title]="oysterCard.title" [image]="oysterCard.image" [stats]="oysterCard.stats"
                     [grants]="oysterCard.grants" [footnotes]="oysterCard.footnotes" [subtitle]="oysterCard.subtitle"></app-stat-card>
    </div>
  </div>
</app-dashboard-panel-template>

<ng-template #stackedChart let-chartObj="chartObj">
  <div>
    <div class="d-flex">
      <div class="stat-title">{{chartObj.title}}&nbsp;</div><app-grants-link [grants]="chartObj.grants"></app-grants-link>
    </div>
    <div *ngIf="!!chartObj.subtitle" class="stat-subtitle-large">{{chartObj.subtitle}}</div>
    <app-chart-wrapper [data]="chartObj.traceData" [layout]="chartObj.layout" [class]="panelClass" [defId]="chartObj.defId"></app-chart-wrapper>
  </div>
</ng-template>
