<mgl-map
  [style]="'mapbox://styles/nfwf/ck40h9qtv1b4i1cttsqcxufj7'"
  [zoom]="[mapZoom]"
  [maxZoom]="10"
  [minZoom]="1"
  [center]="mapCenter"
  (load)="mapInitialized($event)"
  [attributionControl]="false"
>
  <mgl-control mglNavigation [showCompass]="false"></mgl-control>
  <mgl-control mglAttribution [compact]="true"></mgl-control>
  <mgl-control>
    <div class="reset-button">
      <button (click)="mapReset()" title="Recenter map">
        <img src="assets/noun-full-screen-5642417.svg">
      </button>
    </div>
  </mgl-control>
  <ng-container *ngFor="let o of mapData">
    <mgl-marker *ngFor="let pt of o.points" [lngLat]="pt">
      <div
        (click)="selectPoint(o, pt)"
        class="marker"
        [ngClass]="{
          'ecrf': o.program.indexOf('Emergency') > -1,
          'ncrf': o.program.indexOf('National') > -1,
          'sandy': o.program.indexOf('Hurricane') > -1,
          'sandy-ncrf': o.program.indexOf('Co-Funded') > -1,
          'selected': o.ez_grant_id === mapSelectedGrant?.ez_grant_id
          }"
      ></div>
    </mgl-marker>
  </ng-container>
</mgl-map>
<div class="map-legend">
  <div class="legend-section">
    <div class="marker ecrf"></div>
    <div class="label">ECRF</div>
  </div>
  <div class="legend-section">
    <div class="marker ncrf"></div>
    <div class="label">NCRF</div>
  </div>
  <div class="legend-section">
    <div class="marker sandy"></div>
    <div class="label">SANDY</div>
  </div>
  <div class="legend-section">
    <div class="marker sandy-ncrf"></div>
    <div class="label">SANDY-NCRF</div>
  </div>
</div>
<div class="map-info-box">
  <ng-container *ngIf="!!mapSelectedGrant">
    <ng-container *ngTemplateOutlet="grantInfoCard; context: {grant: mapSelectedGrant}"></ng-container>
    <ng-container *ngFor="let grant of grantsWithTheSamePoints">
      <hr>
      <ng-container *ngTemplateOutlet="grantInfoCard; context: {grant: grant}"></ng-container>
    </ng-container>
  </ng-container>
  <div *ngIf="!mapSelectedGrant" class="default-msg">
    Choose a point on the map above to view details on a project
  </div>
</div>


<ng-template #grantInfoCard let-grant="grant">
  <div>
    <div class="info-row">
      <div class="info-label">Program & Year:</div>
      <div class="info-value">
        {{grant.program}} / {{grant.grant_year}}
      </div>
    </div>
    <div class="info-row">
      <div class="info-label">Title:</div>
      <div class="info-value">
        <ng-container *ngIf="!!grant.ez_grant_id">
          <a href="https://www.nfwf.org/grants/grants-library/profile?egid={{grant.ez_grant_id}}" target="_blank">{{grant.grant_title}}</a>
        </ng-container>
        <ng-container *ngIf="!grant.ez_grant_id">
          {{grant.grant_title}}
        </ng-container>
      </div>
    </div>
    <div class="info-row">
      <div class="info-label">Grantee:</div>
      <div class="info-value">{{grant.grantee}}</div>
    </div>
    <div class="info-row">
      <div class="info-label">Easygrant ID:</div>
      <div class="info-value">{{grant.ez_grant_id}}</div>
    </div>
  </div>
</ng-template>
