import { NgModule } from '@angular/core';
import { NotifierModule } from 'angular-notifier';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { PlotlyViaWindowModule } from 'angular-plotly.js';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { CrDashboardComponent } from './cr-dashboard/cr-dashboard.component';
import { FiltersPanelComponent } from './panels/filters-panel/filters-panel.component';
import { ProgramSummaryPanelComponent } from './panels/program-summary-panel/program-summary-panel.component';
import { EcologicalBenefitsPanelComponent } from './panels/ecological-benefits-panel/ecological-benefits-panel.component';
import { SocioeconomicPanelComponent } from './panels/socioeconomic-panel/socioeconomic-panel.component';
import { DashboardPanelTemplateComponent } from './components/dashboard-panel-template/dashboard-panel-template.component';
import { StatCardComponent } from './components/stat-card/stat-card.component';
import { GrantsLinkComponent } from './components/grants-link/grants-link.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTableComponent } from './components/data-table/data-table.component';
import { AppRoutingModule } from './app-routing.module';
import { ChartWrapperComponent } from './components/chart-wrapper/chart-wrapper.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {InfoButtonComponent} from './components/info-button/info-button.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { OverviewMapComponent } from './components/overview-map/overview-map.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    CrDashboardComponent,
    FiltersPanelComponent,
    ProgramSummaryPanelComponent,
    EcologicalBenefitsPanelComponent,
    SocioeconomicPanelComponent,
    DashboardPanelTemplateComponent,
    StatCardComponent,
    GrantsLinkComponent,
    DataTableComponent,
    ChartWrapperComponent,
    InfoButtonComponent,
    OverviewMapComponent
  ],
  imports: [
    BrowserModule,
    PlotlyViaWindowModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    AppRoutingModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12
        },
        vertical: {
          position: 'top',
          distance: 12
        }
      },
      behaviour: {
        autoHide: false,
        onClick: false,
        onMouseover: false,
        showDismissButton: false,
        stacking: false
      },
      animations: {
        show: {
          preset: 'fade'
        }
      }
    }),
    NgbTooltipModule,
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoibmZ3ZiIsImEiOiJjanhuZXVhNDQwZHloM2lsMzJseWlndW5lIn0.p_Zb0O49DVmCdIKFiSB6dA',
    })
  ],
  providers: [
    HttpClient
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
