<div class="d-flex w-100 flex-center">
  <img src="/assets/NFWF_logo_stacked_blue.png">
</div>
<div class="subpanel-float">
  <div class="sub-panel">
    <div class="d-flex filter-hdr">
      <h2>Filters:</h2>
      <button class="clear-btn" title="Clear All Filters" (click)="clearFilters()">X</button>
    </div>
    <div *ngFor="let ctrl of filterCtrls; let idx = index" style="margin-bottom: 1rem;">
      <ng-select
        [formControl]="ctrl"
        [multiple]="false"
        [closeOnSelect]="true"
        (change)="changeFilterField(idx)"
        placeholder="Select a filter"
        style="margin-bottom: .25rem;"
      >
        <ng-option *ngFor="let filter of filterOptions[idx]" [value]="filter">{{getFilterLabel(filter)}}</ng-option>
      </ng-select>
      <!-- Multiselect -->
      <ng-select *ngIf="!!ctrl.value"
                 [formControl]="filterValCtrls[idx]"
                 [multiple]="true"
                 [closeOnSelect]="false"
                 [items]="possibleValues[idx] || []"
                 (change)="changeFilterValues(idx)"
                 placeholder="Select value(s)"
      ></ng-select>
    </div>
  </div>
  <div class="sub-panel">
    <h2>Data Type:<app-info-button style="vertical-align: super;" [tooltipText]="tooltip"></app-info-button></h2>
    <div class="radio-bar">
    <span>
      <input type="radio" name="dataType" value="all" [formControl]="dataTypeFilter">All
    </span>
      <span>
      <input type="radio" name="dataType" value="final" [formControl]="dataTypeFilter">Final Only
    </span>
    </div>
  </div>
  <div class="sub-panel">
    <button (click)="exportData()" [disabled]="exportActive">
      {{!exportActive ? 'Export to CSV' : 'Downloading, please wait...'}}
    </button>
    <div class="about-data">
      Data accurate as of {{asOfDate | date:'shortDate'}}
    </div>
  </div>
</div>


<ng-template #notifySpinner>
  <img class="spinner-img" src="assets/loading.gif">
</ng-template>
