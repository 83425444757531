import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormControl} from "@angular/forms";
import {ApiCallsService} from '../../api-calls.service';
import {FILTER_TYPES} from '../../nfwf-interface';
import {Observable, Subject, switchMap, tap} from 'rxjs';
import {saveAs} from 'file-saver';
import { NotifierService } from 'angular-notifier';


@Component({
  selector: 'app-filters-panel',
  templateUrl: './filters-panel.component.html',
  styleUrls: ['./filters-panel.component.scss']
})

export class FiltersPanelComponent implements OnInit {
  @ViewChild('notifySpinner', { static: true }) notifySpinner!: TemplateRef<any>;
  public selectedFields: FormArray = new FormArray<FormControl>([]);
  public filterOptions: string[][] = [];

  public selectedValues: FormArray = new FormArray<FormControl>([]);
  public possibleValues: string[][] = [];

  public dataTypeFilter: FormControl = new FormControl('all');
  private _metrics$: Subject<any> = new Subject<any>();

  public msSettings = {
    singleSelection: false,
    defaultOpen: false
  }

  public exportActive = false;

  public tooltip = 'Final Only displays measured/modeled results upon completion of the grant. All displays targets for grants in progress and measured/ modeled results for completed grants';

  // This is my way of typing the abstract controls to form controls so I can use them in an *ngFor
  get filterCtrls(): FormControl[] {
    return this.selectedFields.controls.map((ctrl: AbstractControl) => ctrl as FormControl);
  }

  get filterValCtrls(): FormControl[] {
    return this.selectedValues.controls.map((ctrl: AbstractControl) => ctrl as FormControl);
  }

  // Array of the possible filters
  get allFilterChoices(): string[] {
    return Object.values(FILTER_TYPES);
  }

  get asOfDate(): string{
    return this._apiSvc.asOfDate;
  };

  constructor(private _apiSvc: ApiCallsService, private _notifySvc: NotifierService) {
    this._metrics$.pipe(
      switchMap((obs: Observable<any>) => obs)
    ).subscribe();
  }

  ngOnInit(): void {
    // Initialize our first form control
    this._addFilterCtrls();
    // Track the data type radio control for any changes
    this.dataTypeFilter.valueChanges.subscribe(
      () => this._makeMetricsApiCall()
    );
    // Pull metrics for all data
    this._makeMetricsApiCall();
  }

  public getFilterLabel(filterVal: string): string {
    return Object.keys(FILTER_TYPES).find(
      (key: string) => FILTER_TYPES[key] === filterVal
    ) || '';
  }

  public clearFilters(): void {
    this.filterCtrls[0].reset(null);
    this.changeFilterField(0);
  }

  /**
   * User changed one of the filter fields
   * @param curFieldIndex
   */
  public changeFilterField(curFieldIndex: number): void {
    // First we need to delete any fields (filters or values) after this one
    this._removeFilterFields(curFieldIndex);
    // If we have any selected values for this field we need to clear them
    this.filterValCtrls[curFieldIndex].reset([]);
    // Make our api call to pull the possible values for this filter
    // (it will also clear those values if they selected blank)
    this._makeFilterValueApiCall(curFieldIndex);
    // Make our metrics call
    this._makeMetricsApiCall();
  }

  /**
   * User selected/de-selected values for a filter field
   */
  public changeFilterValues(curFieldIndex: number): void {
    // First we need to delete any fields (filters or values) after this one
    this._removeFilterFields(curFieldIndex);
    // Now add new ones if the user actually selected some values
    if (this.filterValCtrls[curFieldIndex].value.length > 0) {
      this._addFilterCtrls(curFieldIndex);
    }
    // Make our metrics call
    this._makeMetricsApiCall();
  }

  /**
   * Removes all controls for filters and values AFTER the index of the control that was changed
   * @param curCtrlIndex
   */
  private _removeFilterFields(curCtrlIndex: number): void {
    // We need to remove every control after the current one
    while (this.selectedFields.controls.length > (curCtrlIndex + 1)) {
      this.selectedFields.removeAt(-1);
      this.selectedValues.removeAt(-1);
    }
    // Now clear any of the filter options for any filter fields past this one
    this.filterOptions.splice(curCtrlIndex + 1);
    // Clear any value options for any fields past this one
    this.possibleValues.splice(curCtrlIndex + 1);
  }

  private _addFilterCtrls(curCtrlIndex?: number): void {
    // Only add a new field if we haven't hit the limit
    if (curCtrlIndex !== this.allFilterChoices.length - 1) {
      const ctrl = new FormControl(null);
      this.selectedFields.push(ctrl);
      // And initialize the options that need to go with it
      this.filterOptions.push(this._remainingFilterChoices((curCtrlIndex || 0) + 1));
      // Now add the value control
      const valCtrl = new FormControl([]);
      this.selectedValues.push(valCtrl);
    }
  }

  /**
   *
   * @param curIndex - The index of the values we are adding to the array
   * @private
   */
  private _remainingFilterChoices(curIndex: number): string[] {
    const prevOptions = this.filterOptions[curIndex - 1] || this.allFilterChoices;
    const prevFilter: string = this.selectedFields.getRawValue()[curIndex - 1] || '';
    return prevOptions.filter((o: string) => o !== prevFilter);
  }

  private _createFiltersObj(limiterIndex?: number): any {
    const prevFilters: any = {};
    // First add the data type filter
    prevFilters['data-type'] = this.dataTypeFilter.value;
    this.filterCtrls.forEach((ctrl: FormControl, idx: number) => {
      if (typeof limiterIndex === 'undefined' || idx < limiterIndex) {
        const filter: string = ctrl.value;
        if (!!filter) {
          prevFilters[filter] = this.filterValCtrls[idx].value.join(',');
        }
      }
    });

    return prevFilters;
  }

  /**
   * Get all of the possible values for the selected filter (taking into account previous filters)
   * @param ctrlIndex
   * @private
   */
  private _makeFilterValueApiCall(ctrlIndex: number): void {
    const list = this.filterCtrls[ctrlIndex].value;
    this.possibleValues[ctrlIndex] = [];

    // Only make the api call if the user actually selected a filter and not blank
    if (!!list) {
      const prevFilters: any = this._createFiltersObj(ctrlIndex);
      this._apiSvc.getListValues(list, prevFilters).pipe(
        tap((data: any[]) => this.possibleValues[ctrlIndex] = data.map(o => o.name))
      ).subscribe();
    }
  }

  /**
   * Make our metrics data call
   * @param ctrlIndex
   * @private
   */
  private _makeMetricsApiCall(): void {
    this._notifySvc.show({
      message: 'Refreshing data...',
      type: 'default',
      template: this.notifySpinner
    });
    const prevFilters: any = this._createFiltersObj();
    this._metrics$.next(this._apiSvc.getMetrics(prevFilters).pipe(tap(() => this._notifySvc.hideAll())));
  }

  public exportData(): void {
    const prevFilters: any = this._createFiltersObj();
    this.exportActive = true;
    this._apiSvc.exportData(prevFilters).subscribe((data: any[]) => {
      // Loop through the data and convert it to a csv file
      const fileData = data.map((row: any) => '"' + Object.values(row).join('","') + '"');
      // Now push the headers to the first row of the data
      // fileData.unshift(ALL_DATA_EXPORT_HEADERS.join(','));
      fileData.unshift(Object.keys(data[0]).join(','));
      // Now export the file
      const blob = new Blob([fileData.join('\n')], {type: 'text/plain;charset=utf-8'});
      saveAs(blob, 'Coastal Resilience Data.csv');
      this.exportActive = false;
    });
  }
}
