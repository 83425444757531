import { Component, OnInit } from '@angular/core';
import {switchMap, tap} from 'rxjs';
import {GrantInfoData} from '../../nfwf-interface';
import {ApiCallsService} from '../../api-calls.service';


@Component({
  selector: 'app-overview-map',
  templateUrl: './overview-map.component.html',
  styleUrls: ['./overview-map.component.scss']
})
export class OverviewMapComponent implements OnInit {
  public mbMap: any;
  public mapData: GrantInfoData[] = [];
  public mapBounds: number[][] = [];
  public mapDefaults: any = {
    center: [-97.21110993278828, 39.26914665065118],
    zoom: 3
  }
  public mapCenter = this.mapDefaults.center;
  public mapZoom = this.mapDefaults.zoom;
  public mapSelectedGrant: GrantInfoData | null = null;
  // These are grants that have the exact same point as the grant that was selected
  // This will allow us to display info for grants with markers that are stacked on each other
  public grantsWithTheSamePoints: GrantInfoData[] = [];

  constructor(private _apiSvc: ApiCallsService) { }

  ngOnInit(): void {
    // Subscribe to the metrics data pull so we repull the map information every time the
    // user changes the filters and reruns the query
    this._apiSvc.metricsDataObs$.pipe(
      switchMap(() => this._apiSvc.getGrantInfo(this._apiSvc.metricsFilters.value)),
      // Only return data that has lat and lon for the map
      tap((data: GrantInfoData[]) => {
        // Clear the selected point
        this.mapSelectedGrant = null;
        // Clear the map bounds
        this.mapBounds = [];
        const mapData: any[] = [];
        let minLat: number;
        let maxLat: number;
        let minLon: number;
        let maxLon: number;
        data.forEach((o: GrantInfoData) => {
          if (o.points.length > 0) {
            o.points.forEach((pt: number[]) => {
              const lat: number = pt[1];
              // We want our map to center itself on the IDL, not the prime meridian
              // So we're going to convert any positive lons to negative by subtracting 360
              // This will effectively put Africa and Europe on the left side of the map
              // allowing us to center Guam, ALaska and Hawaii
              const lon: number = pt[0] > 0 ? pt[0] - 360 : pt[0];
              // We haven't set a latitude yet so just grab the first one to set the min and max
              if (typeof minLat === 'undefined') {
                minLat = lat;
                maxLat = lat;
              } else if (lat < minLat) {
                minLat = lat;
              } else if (lat > maxLat) {
                maxLat = lat
              }
              // We haven't set a longitude yet so just grab the first one to set the min and the max
              if (typeof minLon === 'undefined') {
                minLon = lon;
                maxLon = lon;
              } else if (lon < minLon) {
                minLon = lon;
              } else if (lon > maxLon) {
                maxLon = lon
              }
            })
            mapData.push(o);
          }
        });
        this.mapData = mapData;
        if (this.mapData.length > 0) {
          // @ts-ignore
          this.mapBounds = [[minLon, minLat], [maxLon, maxLat]];
        }
        // Now set the map boundary
        this.mapReset();
      })
    ).subscribe();
  }

  public mapInitialized(evt: any): void {
    this.mbMap = evt;
  }

  /**
   * Display grant information about the feature that was clicked
   * A grant can have multiple features/points associated with it
   * Clicking on one point will highlight all points tied to the grant
   * If the grant was already selected then clicking it again will un-select it
   * @param grantObj - Data object for the parent grant of the marker that was clicked
   */
  public selectPoint(grantObj: GrantInfoData, pt: [number, number]): void {
    if (grantObj.ez_grant_id === this.mapSelectedGrant?.ez_grant_id) {
      this.mapSelectedGrant = null;
      this.grantsWithTheSamePoints = [];
    } else {
      this.mapSelectedGrant = grantObj;
      // Loop through all the map data and find all grants that have a point with the EXACT same lat/lon
      // This is for 'Pipeline Projects'. Basically the same grant goes through different stages
      // and gets different grant ids.
      this.grantsWithTheSamePoints = this.mapData.filter((o: GrantInfoData) => {
        // Skip the grant if it is the exact one the user selected when they clicked the marker
        if (o.ez_grant_id !== grantObj.ez_grant_id) {
          return !!o.points.find((p: [number, number]) => p[0] === pt[0] && p[1] === pt[1]);
        } else {
          return false;
        }
      });
    }
  }

  public mapReset(): void {
    if (!!this.mbMap) {
      if (this.mapBounds.length > 0) {
        this.mbMap.fitBounds(this.mapBounds, {padding: 30});
      } else {
        this.mbMap.easeTo({
          center: this.mapDefaults.center,
          zoom: this.mapDefaults.zoom
        });
      }
    }
  }

  /**
   * The api returns the lon and lat as strings
   * The code needs numbers
   * This function converts the values
   * @param pt
   */
  public convertLonLat(pt: GrantInfoData): [number, number] {
    return [Number(pt.lon), Number(pt.lat)];
  }
}
