import { Component, OnInit } from '@angular/core';
import {
  COLOR_RAMPS,
  MARKER_LINE_PROPS,
  BOTTOM_LEGEND_LAYOUT,
  CHART_PROPERTIES,
  STAT_CARD_PROPERTIES,
  MetricSummary, MeasureSumData, MeasureAvgData, SubCategorySumData, SubCategoryAvgData, GrantsObj
} from "../../nfwf-interface";
import {ApiCallsService} from '../../api-calls.service';
import {delay, tap} from 'rxjs';

@Component({
  selector: 'app-ecological-benefits-panel',
  templateUrl: './ecological-benefits-panel.component.html',
  styleUrls: ['./ecological-benefits-panel.component.scss']
})

export class EcologicalBenefitsPanelComponent implements OnInit {
  public panelClass = 'Ecological';
  public label = 'Ecological Highlights';
  public includeDisclaimer = true;
  private _measureSums: MeasureSumData[] = [];
  private _subCategorySums: SubCategorySumData[] = [];
  private _measureAvgs: MeasureAvgData[] = [];
  private _subCategoryAvgs: SubCategoryAvgData[] = [];

  public statCards: {title: string, img: string, measure: string, grants: GrantsObj, stats: number, defId: string}[] = [];

  public oysterCard: any = null;

  public biomassChartInfo!: { traceData: any[], layout: any, grants: GrantsObj, title: string, subtitle: string, defId: string} | null;
  public acresChartInfo!: { traceData: any[], layout: any, grants: GrantsObj, title: string, subtitle: string, defId: string};
  public milesChartInfo!: { traceData: any[], layout: any, grants: GrantsObj, title: string, subtitle: string, defId: string};

  public get displayPanel(): boolean {
    return !!this.acresChartInfo || !!this.milesChartInfo || !!this.statCards.length || !!this.biomassChartInfo || !!this.oysterCard;
  }

  constructor(private _apiSvc: ApiCallsService) { }

  ngOnInit(): void {
    // Subscribe to the metrics data pull
    this._apiSvc.metricsDataObs$.pipe(
      tap(() => {
        this.biomassChartInfo = null;
      }),
      tap((data: MetricSummary) => {
        this._subCategorySums = data.sub_category_sums;
        this._subCategoryAvgs = data.sub_category_avgs;
        this._measureSums = data.measure_sums;
        this._measureAvgs = data.measure_avgs;
        this._buildStatCards();
        this._buildOysterStatCard();
        this.acresChartInfo = this._buildHorizontalStackedChart('acresrestored','Acres');
        this.milesChartInfo = this._buildHorizontalStackedChart('milesrestored','Miles');
      }),
      delay(100),
      tap((data: MetricSummary) => this._buildBiomassChart())
    ).subscribe();
  }

  private _buildBiomassChart(): void {
    const msr = CHART_PROPERTIES.biomasschange.msr;
    const labels: string[] = [];
    const vals1: number[] = [];
    const vals2: number[] = [];
    const grants1: number[] = [];
    const grants2: number[] = [];
    const msrAvg = this._measureAvgs.find((o: MeasureAvgData) => o.class === this.panelClass && o.measure === msr);
    const colorramp = COLOR_RAMPS.ecological;
    const colors = [colorramp[1], colorramp[2]];
    const titles = ['1 year', '2-5 year'];

    this._subCategoryAvgs.forEach((o:SubCategoryAvgData) => {
      if (o.class === this.panelClass && o.measure === msr) {
        labels.push(o.sub_category);
        vals1.push(Math.round(Number(o.first_value) * 10) / 10);
        vals2.push(Math.round(Number(o.last_value) * 10) / 10);
        grants1.push(o.grant_count);
        grants2.push(o.grant_count);
      }
    });

    const data = [vals1, vals2].map((vals: number[], idx: number) => ({
      x: labels,
      y: vals,
      name: titles[idx],
      meta: labels,
      type: 'bar',
      texttemplate: '<b>%{y:,}</b>',
      textposition: 'auto',
      textfont: {
        size: 11
      },
      hoverinfo: 'none',
      marker: {
        color: colors[idx],
        line: MARKER_LINE_PROPS
      },
    }));

    const layout = {
      barmode: 'group',
      height: 200,
      autosize: true,
      font: {
        size: 14
      },
      // width: 380,
      margin: {
        b: 30,
        l: 0,
        r: 10,
        t: 0
      },
      xaxis: {
        tickmode: 'array',
        tickvals: labels.map((l, i) => {return i}),
        ticktext: labels.map(l => '<b>' + l.replace(' /', '<br>/') + '</b>'),
        tickfont: {
          size: 9
        },
        fixedrange: true
      },
      yaxis: {
        visible: false,
        fixedrange: true
      },
      legend: BOTTOM_LEGEND_LAYOUT
    };

    this.biomassChartInfo = !!msrAvg?.grant_count ? {
      traceData: data,
      layout: layout,
      title: CHART_PROPERTIES['biomasschange'].title,
      subtitle: 'Completed Projects Only',
      grants: {count: msrAvg?.grant_count || 0, defId: 'biomasschange'},
      defId: 'biomasschange'
    } : null;
  }

  /**
   * Creates our horizontal stacked bar chart
   * @param props - The properties object we pull from CHART_PROPERTIES
   * @param label - The text value that we append to the total we calculate (ie 250 Miles)
   * @private
   */
  private _buildHorizontalStackedChart(defId: string, label: string): any {
    const labels: string[] = [];
    const vals: number[] = [];
    const prop = CHART_PROPERTIES[defId];
    const measure = prop.msr;
    const msrSum = this._measureSums.find((o: MeasureSumData) => o.class === this.panelClass && o.measure === measure);
    const colors = COLOR_RAMPS.ecological;
    // Sort through the data and filter on the class and measure properties
    // Class in this case will be Ecological
    this._subCategorySums.filter(o => o.class === this.panelClass && o.measure === measure)
      .sort((a, b) => Number(a.value) > Number(b.value) ? -1 : 1)
      .forEach((o: SubCategorySumData) => {
        labels.push(o.sub_category);
        // Convert the value to a number
        vals.push(Math.round(Number(o.value)));
      })

    return !!msrSum?.grant_count ? {
      traceData: this._stackedChartData(vals, labels, colors),
      layout: this._stackedChartLayout(),
      subtitle: Math.round(Number(msrSum?.value || 0)).toLocaleString() + ' ' + label,
      grants: {count: msrSum?.grant_count || 0, defId: defId},
      title: prop.title,
      defId: defId
    } : null;
  }

  private _stackedChartLayout(): any {
    return {
      barmode: 'stack',
      showlegend: true,
      legend: BOTTOM_LEGEND_LAYOUT,
      height: 140,
      autosize: true,
      margin: {
        b: 0,
        l: 0,
        r: 0,
        t: 0
      },
      xaxis: {
        visible: false,
        fixedrange: true
      },
      yaxis: {
        fixedrange: true
      }
    };
  }

  private _stackedChartData(vals: number[], labels: string[], colors: string[]): any {
    return vals.map((val: number, idx: number) => ({
      x: [val],
      y: [1],
      meta: labels[idx],
      name: labels[idx],
      type: 'bar',
      orientation: 'h',
      texttemplate: '<b>%{x:,} </b>',
      textposition: 'auto',
      textfont: {
        size: 11
      },
      hoverinfo: 'none',
      marker: {
        color: colors[idx],
        line: MARKER_LINE_PROPS
      },
    }));
  }

  /**
   * Creates our 3 single image stat cards
   * If we don't have data for a card we won't create the card
   * @param data
   * @private
   */
  private _buildStatCards(): void {
    const defIds = ['species', 'fishpassage', 'streammiles'];
    const cardTitles = defIds.map(id => STAT_CARD_PROPERTIES[id].title);
    const cardMsrs = defIds.map(id => STAT_CARD_PROPERTIES[id].msr);
    const statCardsMeta = [
      {
        title: cardTitles[0],
        img: 'noun-forest-2024106-539536.png',
        measure: cardMsrs[0],
        grants: {},
        stats: 0,
        defId: defIds[0]
      },
      {title: cardTitles[1], img: 'FishGroup-01.png', measure: cardMsrs[1], grants: {}, stats: 0, defId: defIds[1]},
      {title: cardTitles[2], img: 'noun-river-2419961-539536.png', measure: cardMsrs[2], grants: {}, stats: 0, defId: defIds[2]}
    ];

    this.statCards = statCardsMeta.map((o: any, idx: number) => {
      // See if we have data for this measure
      const msrData = this._measureSums.find(d => d.measure === o.measure && d.class === this.panelClass);
      const newObj = Object.assign({}, o);
      if (!!msrData) {
        newObj.grants = {count: msrData.grant_count, defId: defIds[idx]};
        // Neal asked me to round the numbers
        newObj.stats = Math.round(Number(msrData.value));
      }
      return newObj;
    }).filter(o => o.grants.count > 0);
  }

  private _buildOysterStatCard(): void {
    const msr = STAT_CARD_PROPERTIES.abundancechange.msr;
    const data = this._measureAvgs.find((o: MeasureAvgData) => o.class === this.panelClass && o.measure === msr);
    const chartTitle = STAT_CARD_PROPERTIES.abundancechange.title;
    if (!!data) {
      this.oysterCard = {
        title: chartTitle,
        image: 'noun-oyster-3386338-A9CA9B.png',
        subtitle: 'Completed Projects Only',
        grants: {count: data.grant_count, defId: 'abundancechange'},
        stats: [
          (Math.round(Number(data.first_value) * 10) / 10).toString() + '%',
          (Math.round(Number(data.last_value) * 10) / 10).toString() + '%'
        ],
        footnotes: ['1 yr', '2-5 yr']
      }
    } else {
      this.oysterCard = null;
    }
  }

}
