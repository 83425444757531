import { Component, OnInit } from '@angular/core';
import {
  COLOR_RAMPS,
  MARKER_LINE_PROPS,
  BOTTOM_LEGEND_LAYOUT,
  CHART_PROPERTIES,
  MetricSummary, GrantTypeData, GranteeTypeData, ProgramData
} from "../../nfwf-interface";
import {ApiCallsService} from '../../api-calls.service';
import {tap} from 'rxjs';

@Component({
  selector: 'app-program-summary-panel',
  templateUrl: './program-summary-panel.component.html',
  styleUrls: ['./program-summary-panel.component.scss']
})

export class ProgramSummaryPanelComponent implements OnInit {
  public label = 'Program Overview';
  public includeDisclaimer = false;
  public colors = COLOR_RAMPS.programSummary;
  public tripleChartData: any;
  public tripleChartLayout: any;
  public leveragedFundsInfoText: string = 'Matched funds refer to non-federal matching contributions (non-federal dollar or equivalent goods and services). Leveraged funds refers to additional federal funds obtained for the project.';

  public grantChartInfo!: { traceData: any[], layout: any, title: string, defId: string};
  public granteeChartInfo!: { traceData: any[], layout: any, title: string, defId: string};

  constructor(private _apiSvc: ApiCallsService) { }

  ngOnInit(): void {
    // Subscribe to the metrics data pull
    this._apiSvc.metricsDataObs$.pipe(
      tap((data: MetricSummary) => {
        this._createGrantChart(data.grants_by_grant_type);
        this._createGranteeChart(data.grants_by_grantee_type);
        this._createTripleCharts(data.grants_by_program);
      })
    ).subscribe();
  }

  // The first 2 charts are pie charts and the last charts is a triple vertical stacked bar chart
  private _createTripleCharts(programData: ProgramData[]): void {
    const matchedFundValues: number[] = [];
    const leverageFundValues: number[] = [];
    const pieChartLabels: string[] = [];
    const pieChartValues: number[][] = [[], [], []];
    const pieChartTotals: number[] = [];
    const pieChartTitles: string[] = [
      '<b>Grants Awarded</b>',
      '<b>Funds Awarded<br>($Millions)</b>'
    ];
    const tripleChartTitles: string[] = pieChartTitles.concat('<b>Matched and Leveraged<br>Funds ($Millions)</b>');
    // We need to hard code our colors based on the program name
    const pieColors: any[] = [];

    programData.forEach((o: ProgramData) => {
      pieChartLabels.push(o.program);
      // We need to add a specific color for each program so it matches the map
      if (o.program.toLowerCase().indexOf('hurricane') > -1) {
        pieColors.push('#008fba');
      } else if (o.program.toLowerCase().indexOf('national') > -1) {
        pieColors.push('#00566c');
      } else if (o.program.toLowerCase().indexOf('co-funded') > -1) {
        pieColors.push('#153b62');
      } else {
        pieColors.push('#80c7da');
      }
      pieChartValues[0].push(o.grant_count);
      pieChartValues[1].push(this._reduceMillions(o.funds_awarded));
      matchedFundValues.push(this._reduceMillions(o.matching_funds));
      leverageFundValues.push(this._reduceMillions(o.leverage_funds));
    });
    pieChartTotals[0] = pieChartValues[0].reduce((a, b) => a + b);
    pieChartTotals[1] = Math.round((pieChartValues[1].reduce((a, b) => a + b)));

    this.tripleChartLayout = {
      height: 300,
      barmode: 'stack',
      autosize: true,
      grid: {rows: 1, columns: 3},
      legend: {
        orientation: 'h',
        font: {
          size: 9
        },
        itemclick: false,
        itemdoubleclick: false,
        yanchor: 'top',
        y: 0,
        traceorder: 'normal'
      },
      legend2: {
        orientation: 'h',
        font: {
          size: 9,
        },
        itemclick: false,
        itemdoubleclick: false,
        yanchor: 'bottom',
        yref: 'paper',
        y: 1,
        bgcolor: 'transparent',
        xanchor: 'center',
        xref: 'x3',
        x: .82,
      },
      margin: {
        l: 0,
        r: 0,
        b: 20,
        t: 60,
      },
      xaxis: {
        visible: false,
        // fixedrange = true prevents users from being able to click drag zoom
        fixedrange: true,
        range: [0, 1]
      },
      xaxis2: {
        visible: false,
        // fixedrange = true prevents users from being able to click drag zoom
        fixedrange: true,
        range: [0, 1]
      },
      xaxis3: {
        visible: false,
        // fixedrange = true prevents users from being able to click drag zoom
        fixedrange: true,
        range: [-.5, 3.5]
      },
      yaxis: {
        visible: false,
        // fixedrange = true prevents users from being able to click drag zoom
        fixedrange: true,
        // Setting this range places a bit of space between the top of the vertical bar charts and the 2nd legend
        // The vertical bar charts will always max at 1 so by setting the Y axis max to 1.1 the extra .1 is the gap
        range: [0, 1.1]
      },
      annotations: [
        // Create the titles for each donut chart and the stacked bar chart
        ...tripleChartTitles.map((title: string, idx: number) => this._createTripleChartTitleAnnotation(title, idx)),
        // This should create the 2 annotations at the center of the donut charts (nothing for the bar chart)
        ...pieChartTotals.map((total: number, idx: number) => this._createPieChartTotalAnnotation(total, idx))
      ],
    };

    // Create the data objects for both donut charts
    const tmp1: any[]  = pieChartTitles.map((title: string, idx: number) => (
      {
        values: pieChartValues[idx],
        labels: pieChartLabels,
        type: 'pie',
        hole: .4,
        textposition: 'inside',
        textinfo: 'value',
        marker: {
          colors: pieColors,
          line: MARKER_LINE_PROPS
        },
        domain: {
          row: 0,
          column: idx
        },
        hoverinfo: 'none'
      }
    ));
    // Now we need to create the data object for the stacked bar chart
    // It's stacked and each vertical bar is separated by a grantee
    // The actual 'stack' is the matched_fund vs the leverage_fund
    const vals = [matchedFundValues, leverageFundValues];
    // The titles that will be displayed in our 2nd legend
    const names = ['Matched Funds', 'Leveraged Funds'];
    const totals = matchedFundValues.map((val: number, idx: number) => val + leverageFundValues[idx]);
    const tmp2 = vals.map((val: number[], idx: number) => ({
      // Use the labels for the x values so our tables work when the user clicks on the chart
      x: pieChartLabels,
      y: val.map((o: number, i: number) =>  o / totals[i]),
      name: names[idx],
      type: 'bar',
      text: val.map(o => o.toString()),
      textposition: 'auto',
      hoverinfo: 'none',
      showlegend: false,
      marker: {
        color: pieColors,
        line: MARKER_LINE_PROPS,
        pattern: {
          fgopacity: 1,
          fillmode: 'replace',
          bgcolor: 'white',
          shape: '\\',
          solidity: idx === 0 ? 1 : .85,
          size: 5
        }
      },
      cliponaxis: false,
      xaxis: 'x3'
    }));
    // We need to create a data object for Leveraged so we can display a white square with lines in the legend
    // THIS IS ONLY FOR THE SECOND LEGEND!!
    const tmp3 = {
      x: [0],
      y: [0],
      name: 'Leveraged',
      type: 'bar',
      legend: 'legend2',
      visible: 'legendonly',
      marker: {
        color: 'white',
        line: MARKER_LINE_PROPS,
        pattern: {
          fgopacity: 1,
          fillmode: 'replace',
          bgcolor: 'black',
          shape: '\\',
          solidity: .85,
          size: 5
        }
      },
    };
    // We need to create a data object for Matched so we can display a white square in the legend
    // THIS IS ONLY FOR THE SECOND LEGEND!!
    const tmp4 = {
      x: [0],
      y: [0],
      name: 'Matched',
      type: 'bar',
      legend: 'legend2',
      visible: 'legendonly',
      marker: {
        color: 'white',
        line: MARKER_LINE_PROPS
      }
    };


    this.tripleChartData = tmp1.concat(tmp2).concat([tmp3, tmp4]);
  }

  private _createTripleChartTitleAnnotation(title: string, idx: number): any {
    return {
      font: {
        size: 14
      },
      showarrow: false,
      text: title,
      yanchor: 'bottom',
      xanchor: 'center',
      xref: 'x' + (idx + 1),
      x: idx < 2 ? .5 : 1.5,
      yref: 'paper',
      y: 1,
      yshift: 25
    }
  }

  private _createPieChartTotalAnnotation(total: number, idx: number): any {
    return {
      font: {
        size: 20
      },
      showarrow: false,
      text: '<b>' + total + '</b>',
      xanchor: 'center',
      xref: 'x' + (idx + 1),
      x: .5,
      yref: 'paper',
      y: .5
    }
  }

  private _createGrantChart(grantData: GrantTypeData[]): void {
    const labels: string[] = [];
    const vals: number[] = [];
    const colorRamp = COLOR_RAMPS.programSummary;
    const colors = [colorRamp[1], colorRamp[2], colorRamp[0], colorRamp[3], colorRamp[5], colorRamp[6], colorRamp[7]];
    // Sort the data in order by grant type
    // The order was provided by NFWF
    const orderMap: any = {
      'planning': 1,
      'preliminary design': 2,
      'final design': 3,
      'implementation': 4,
      'other': 5
    };
    // grantData.sort((a: GrantTypeData, b: GrantTypeData) => a.grant_count > b.grant_count ? -1 : 1);
    grantData.sort((a: GrantTypeData, b: GrantTypeData) => (orderMap[a.grant_type.toLowerCase()] || 6) < (orderMap[b.grant_type.toLowerCase()] || 6) ? -1 : 1);
    // Now loop through the data and populate the labels and vals
    grantData.forEach((o: GrantTypeData) => {
      labels.push(o.grant_type);
      vals.push(o.grant_count);
    });
    this.grantChartInfo = {
      traceData: this._stackedChartData(vals, labels, colors),
      layout: this._stackedChartLayout(),
      title: CHART_PROPERTIES.granttypes.title,
      defId: 'granttypes'
    }
  }

  private _createGranteeChart(granteeData: GranteeTypeData[]): void {
    const labels: string[] = [];
    const vals: number[] = [];
    const colorRamp = COLOR_RAMPS.programSummary;
    const colors = [colorRamp[1], colorRamp[3], colorRamp[2], colorRamp[0], colorRamp[5], colorRamp[6], colorRamp[7]];
    // Sort the data in descending order by the grant count
    granteeData.sort((a: GranteeTypeData, b: GranteeTypeData) => a.grant_count > b.grant_count ? -1 : 1);
    // Now loop through the data and populate the labels and vals
    granteeData.forEach((o: GranteeTypeData) => {
      labels.push(o.grantee_type);
      vals.push(o.grant_count);
    });
    this.granteeChartInfo = {
      traceData: this._stackedChartData(vals, labels, colors),
      layout: this._stackedChartLayout(),
      title: CHART_PROPERTIES.granteetypes.title,
      defId: 'granteetypes'
    }
  }

  private _stackedChartLayout(): any {
    return {
      barmode: 'stack',
      showlegend: true,
      legend: BOTTOM_LEGEND_LAYOUT,
      height: 140,
      autosize: true,
      margin: {
        b: 0,
        l: 0,
        r: 0,
        t: 0
      },
      xaxis: {
        visible: false,
        fixedrange: true
      },
      yaxis: {
        fixedrange: true
      }
    };
  }

  private _stackedChartData(vals: number[], labels: string[], colors: string[]): any {
    return vals.map((val: number, idx: number) => ({
      x: [val],
      y: [1],
      name: labels[idx],
      meta: labels[idx],
      type: 'bar',
      orientation: 'h',
      texttemplate: '<b>%{x:,} </b>',
      textposition: 'auto',
      textfont: {
        size: 11
      },
      hoverinfo: 'none',
      marker: {
        color: colors[idx],
        line: MARKER_LINE_PROPS
      },
    }));
  }

  private _reduceMillions(val: string): number {
    // The value is given to me as a string so convert it to a number
    let num = Number(val);
    // We need to return the value with at most 1 decimal place
    num = Math.round(num / 100000);
    return num / 10;
  }

}
